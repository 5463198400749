import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import changeParkingTypeAction from 'action-creators/search/change-parking-type';
import trackEventAction from 'action-creators/analytics/track-event';

import { PARKING_TYPE_TOGGLE_CLICK, PARKING_TYPE_TOGGLE_IMPRESSION } from 'lib/analytics/events/index';

import Search from 'models/search';

/**
 * We don't put <MonthlyToggle/> in <MapboxAutocomplete/> because initial SSR render always sets
 * state.app.name to HOME_APP, _even_ when loading the search results page.
 * So it'd be hard/impossible to tell this toggle to not render on search results page on initial page load,
 * and the toggle would briefly and noticeably flash on the screen.
 * Because we always use this toggle in conjunction with the search bar the file belongs here.
 */

const propTypes = {
  currentParkingType: PropTypes.string,
  changeParkingType: PropTypes.func,
  trackEventImpression: PropTypes.func,
  trackEventClick: PropTypes.func,
};

const defaultProps = {
  changeParkingType: () => {},
  // we set desired default here because state.search.currentSearch.parkingType is undefined on initial render.
  currentParkingType: Search.DAILY_PARKING_TYPE,
  trackEventClick: () => {},
  trackEventImpression: () => {},
};

export const MonthlyToggle = ({
  changeParkingType,
  currentParkingType,
  trackEventClick,
  trackEventImpression,
}) => {
  trackEventImpression(currentParkingType);

  const getClasses = isSelected => cx({
    'monthly-toggle-item': true,
    'display-xs-inline-block': true,
    'text-size-xs-20': true,
    'text-size-sm-16': true,
    'selected': isSelected,
  });

  const isMonthlySearch = currentParkingType === Search.MONTHLY_PARKING_TYPE;

  // undesired page reload occurs if submit form on homepage captures event
  const setMonthlyToggle = (newParkingType, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (newParkingType !== currentParkingType) {
      changeParkingType(newParkingType);
      trackEventClick(newParkingType);
    }
  };

  return (
    <Fragment>
      <div
        className={getClasses(!isMonthlySearch)}
        onClick={e => setMonthlyToggle(Search.DAILY_PARKING_TYPE, e)}
      >
        <span>Hourly</span>
      </div>
      <div
        className={getClasses(isMonthlySearch)}
        onClick={e => setMonthlyToggle(Search.MONTHLY_PARKING_TYPE, e)}
      >
        <span>Monthly</span>
      </div>
    </Fragment>
  );
};

MonthlyToggle.propTypes = propTypes;
MonthlyToggle.defaultProps = defaultProps;

const mapStateToProps = state => ({
  currentParkingType: state.search.currentSearch.parkingType,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    // don't kick off an updated quotes request or redirect when toggling on home, hub pages.
    changeParkingType: parkingType => changeParkingTypeAction({
      clearSelected: false,
      keepRoute: true,
      parkingType,
      shouldGetQuotes: false,
    }),
    trackEventImpression: ToggleValue => trackEventAction({
      ...PARKING_TYPE_TOGGLE_IMPRESSION,
      properties: { ToggleValue },
    }),
    trackEventClick: ToggleValue => trackEventAction({
      ...PARKING_TYPE_TOGGLE_CLICK,
      properties: { ToggleValue },
    }),
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyToggle);
