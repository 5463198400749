import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { canUseDOM } from 'exenv';
import Insights, { PageProperties } from '@parkwhiz-js/insights-sdk';
import get from 'lodash/get';

import CoordinatesModel from 'models/coordinates';
import Brand from 'models/brand';

import HomeHeader from 'components/home/header';
import TextToDownload from 'components/common/text-to-download';
import PeopleLoveParkWhiz from 'components/common/value-props/people-love-parkwhiz';
import BasicValueProps from 'components/common/value-props/basic-value-props';
import HowItWorksCarousel, { CONTENT_TEXT } from 'components/common/how-it-works-carousel';
import HowItWorks from 'components/common/how-it-works';

import { PAGEVIEW_EVENT } from 'lib/analytics/events';
import { pageProps, urlProps } from 'lib/analytics/page-properties';
import * as AppContext from 'lib/app-context';

import addMessageAndScrollToTopCreator from 'action-creators/messaging/add-message-and-scroll-to-top';
import trackEventCreator from 'action-creators/analytics/track-event';
import sendAppDownloadTextCreator from 'action-creators/brand/send-app-download-text';
import cleanupHomePageState from 'action-creators/brand/cleanup-home-page-state';

const propTypes = {
  app: PropTypes.string.isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  geoIPLocation: PropTypes.instanceOf(CoordinatesModel).isRequired,
  appContext: PropTypes.string,
  addMessageAndScrollToTop: PropTypes.func.isRequired,
  sendAppDownloadText: PropTypes.func.isRequired,
  insights: PropTypes.instanceOf(Insights).isRequired,
  trackEvent: PropTypes.func.isRequired,
  cleanupHomePageState: PropTypes.func.isRequired,
};

const defaultProps = {
  appContext: AppContext.DESKTOP,
};

class Home extends Component {
  componentWillMount() {
    if (canUseDOM) {
      const { app, trackEvent } = this.props;
      const pageProperties = pageProps({ app });

      trackEvent({
        ...PAGEVIEW_EVENT,
        ...pageProperties,
        name: pageProperties.pageName,
        properties: urlProps(),
      });
    }
  }

  componentWillUnmount() {
    this.props.cleanupHomePageState();
  }

  render() {
    const {
      geoIPLocation,
      appContext,
      app,
      addMessageAndScrollToTop,
      sendAppDownloadText,
      insights,
      trackEvent,
      brand,
    } = this.props;

    return (
      <div className="home-page">
        <HomeHeader
          geoIPLocation={geoIPLocation}
          appContext={appContext}
          addMessageAndScrollToTop={addMessageAndScrollToTop}
          className="padding-bottom-xs-80 padding-bottom-sm-0"
          app={app}
          trackEvent={trackEvent}
          insights={insights}
          brand={brand}
        />
        <div className="margin-top-negative-80 visible-xs">
          <img
            src={get(brand, 'isBestParking', false) ? 'https://s3.amazonaws.com/random-static.parkwhiz/videos/how-it-works-drive-arrive-park-white@2x.jpg' : '/images/home/parkedcar@2x.png'}
          />
          <HowItWorksCarousel
            autoplayInterval={8000}
            initialSlideHeight={375}
            className="padding-bottom-20"
            contentStyle={CONTENT_TEXT}
          />
        </div>
        <div className="row visible-gte-sm hidden-xs">
          <div className="row background-white bottom-shadow-blur margin-top-md-40">
            <HowItWorks appContext={appContext} brand={this.props.brand} />
          </div>
          <TextToDownload
            className="margin-vertical-40"
            sendAppDownloadText={sendAppDownloadText}
            appButtonPosition={1}
            brand={this.props.brand}
          />
          <BasicValueProps />
          <PeopleLoveParkWhiz
            className="margin-vertical-40"
            brand={brand}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    addMessageAndScrollToTop: addMessageAndScrollToTopCreator,
    trackEvent: trackEventCreator,
    sendAppDownloadText: sendAppDownloadTextCreator,
    cleanupHomePageState,
  }, dispatch)
);

const mapStateToProps = (state) => {
  const { geoIPLocation } = state.search;
  const { insights } = state.analytics;
  const {
    name: app,
    appContext,
    locale,
    translations,
  } = state.app;
  const { brand } = state.brand;

  return {
    app,
    appContext,
    geoIPLocation,
    insights,
    brand,
    locale,
    translations,
  };
};

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(Home);
