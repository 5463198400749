import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import Insights from '@parkwhiz-js/insights-sdk';

import { imageCdnAsset } from 'lib/common/url-helpers';

import AppLink from 'containers/common/app-link';
import MapboxAutocomplete from 'containers/mapbox/autocomplete';
import MapboxGeolocation from 'components/mapbox/geolocation';


import CoordinatesModel from 'models/coordinates';
import Brand from 'models/brand';

import { HOME_PAGE_SEARCH_TEXTBOX_IMPRESSION, HOME_PAGE_SEARCH_BUTTON_IMPRESSION, FIND_PARKING_BUTTON_IMPRESSION } from 'lib/analytics/events';
import * as AppContext from 'lib/app-context';
import MonthlyToggle from 'containers/mapbox/autocomplete/monthly-toggle';

const propTypes = {
  className: PropTypes.string,
  geoIPLocation: PropTypes.instanceOf(CoordinatesModel).isRequired,
  appContext: PropTypes.string,
  addMessageAndScrollToTop: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool,
  trackEvent: PropTypes.func.isRequired,
  app: PropTypes.string.isRequired,
  insights: PropTypes.instanceOf(Insights).isRequired,
  brand: PropTypes.instanceOf(Brand),
};

const defaultProps = {
  className: '',
  appContext: AppContext.DESKTOP,
  fullscreen: false,
  brand: new Brand(),
};

export class HomeHeader extends Component {
  static renderAppBadges() {
    return (
      <div className="row hidden-xs hidden-sm">
        <div className="col-xs-12">
          <AppLink appStore="apple" linkProps={{ c: 'home_header' }} desktopOnly>
            <img
              className="display-inline padding-vertical-10 padding-horizontal-20 background-color-transparent-white background-color-hover-transparent-white border-radius-4"
              height="47"
              src={imageCdnAsset('/images/app-badges/app-badge-apple-bare@2x.png')}
              width="163"
            />
          </AppLink>
          <AppLink appStore="android" linkProps={{ c: 'home_header' }} className="margin-left-40" desktopOnly>
            <img
              className="display-inline padding-vertical-10 padding-horizontal-20 background-color-transparent-white background-color-hover-transparent-white border-radius-4"
              height="47"
              src={imageCdnAsset('/images/app-badges/app-badge-android-bare@2x.png')}
              width="163"
            />
          </AppLink>
        </div>
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.onSearchBarVisibilityChange = this.onSearchBarVisibilityChange.bind(this);
    this.onFindParkingButtonVisibilityChange = this.onFindParkingButtonVisibilityChange.bind(this);
    this.state = {
      searchInputViewed: false,
      findParkingButtonViewed: false,
    };
  }

  onSearchBarVisibilityChange(isVisible) {
    if (!this.state.searchInputViewed && this.props.app === 'Home' && this.props.appContext === AppContext.MOBILE) {
      this.setState({ searchInputViewed: isVisible });
      const { trackEvent } = this.props;
      if (isVisible) {
        trackEvent(HOME_PAGE_SEARCH_TEXTBOX_IMPRESSION);
        trackEvent(HOME_PAGE_SEARCH_BUTTON_IMPRESSION);
      }
    }
  }

  onFindParkingButtonVisibilityChange(isVisible) {
    const { app, trackEvent, appContext } = this.props;
    if (!this.state.findParkingButtonViewed && app === 'Home' && appContext === AppContext.MOBILE) {
      this.setState({ findParkingButtonViewed: isVisible });
      if (isVisible) {
        trackEvent(FIND_PARKING_BUTTON_IMPRESSION);
      }
    }
  }

  renderVideoBackground() {
    if (this.props.appContext === AppContext.MOBILE || this.props.appContext === AppContext.TABLET) { return null; }

    return (
      <div className="background-home-video-container hidden-xs hidden-sm">
        <video loop muted autoPlay poster="/images/posters/homepage-header.jpg" className="background-home-video">
          <source src="https://s3.amazonaws.com/random-static.parkwhiz/videos/home-header-3.mp4" type="video/mp4" />
        </video>
      </div>
    );
  }

  render() {
    const { fullscreen, addMessageAndScrollToTop, geoIPLocation, appContext, className, brand } = this.props;
    const mergedClassName = `hero-search padding-top-xs-80 padding-top-md-120 padding-bottom-md-40 ${className}`;

    let btnColor;
    if (fullscreen) {
      btnColor = 'btn-blue';
    } else if (brand && brand.isBestParking) {
      btnColor = 'btn-bp-sm btn-bp-dark';
    } else {
      btnColor = 'btn-watermelon';
    }

    return (
      <form action="/search/" method="get">
        <div className={mergedClassName}>
          { this.renderVideoBackground() }
          <div className="container text-center logo-container">
            <a href="/" className="hidden-xs hidden-sm header-logo-dark">
              <img src={brand.homepageLogoURL} alt={brand.displayName} />
            </a>
            <div className="row visible-xs visible-sm">
              <div className="col-sm-6 col-sm-offset-3 col-xs-8 col-xs-offset-2 margin-top-negative-60">
                <a href="/">
                  <picture>
                    <img src={brand.homepageLogoURL} alt={brand.displayName} />
                  </picture>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <h1 className="text-size-xs-28 text-size-md-42 text-weight-black line-height-full margin-top-0 margin-bottom-10 text-color-white">
                  <div className="visible-md visible-lg">
                    PARKING JUST GOT A LOT SIMPLER
                  </div>
                  <div className="visible-xs visible-sm">
                    PARKING JUST
                    <br />
                    GOT A LOT SIMPLER
                  </div>
                </h1>
                <p className="text-color-white text-size-24 font-family-georgia visible-md visible-lg">Book the Best Spaces &amp; Save Up to 50%</p>
              </div>
            </div>
            <div className="row margin-bottom-md-20 margin-bottom-xs-15">
              <div className="text-left-gte-md col-xs-12 col-md-offset-2 col-md-8 padding-top-20">
                <MonthlyToggle />
              </div>
            </div>
            <div className="row margin-bottom-md-40">
              <div className="col-md-8 col-md-offset-2">
                <div className="input-group cta-hero-input with-map-pin-icon">
                  <VisibilitySensor onChange={this.onSearchBarVisibilityChange} partialVisibility>
                    <MapboxAutocomplete
                      {...this.props}
                      className="gplaces-autocomplete"
                      buttonClassName={`btn ${btnColor}`}
                      buttonText="FIND PARKING"
                      dropdownClassName="location-suggestion-bar-homepage"
                      inputClassName="form-control focusField gplaces-input-dropdown"
                      addMessageAndScrollToTop={addMessageAndScrollToTop}
                      geoIPLocation={geoIPLocation}
                      placeholder={appContext === AppContext.MOBILE ? 'Search Address or Place' : 'Search Address, Place or Event'}
                      app={this.props.app}
                      appContext={this.props.appContext}
                      trackEvent={this.props.trackEvent}
                      insights={this.props.insights}
                      id="home-autocomplete"
                      fullscreen
                    />
                  </VisibilitySensor>
                </div>
                <div className="form-group visible-sm visible-xs">
                  <VisibilitySensor onChange={this.onFindParkingButtonVisibilityChange} partialVisibility>
                    <MapboxGeolocation
                      {...this.props}
                      addMessageAndScrollToTop={addMessageAndScrollToTop}
                      geoIPLocation={geoIPLocation}
                      className="text-color-white text-size-16 margin-top-20"
                      text="Find Parking Nearby"
                      app={this.props.app}
                      appContext={this.props.appContext}
                      trackEvent={this.props.trackEvent}
                      insights={this.props.insights}
                    />
                  </VisibilitySensor>
                </div>
              </div>
            </div>
            { HomeHeader.renderAppBadges() }
          </div>
        </div>
      </form>
    );
  }
}

HomeHeader.propTypes = propTypes;
HomeHeader.defaultProps = defaultProps;

export default HomeHeader;